import { PREFIX, LANG } from "../../constants/languages";
import { MY_PETS } from "../../constants/myPets";
import { t } from "i18next";
import { PetGenderEnum, PetTypesEnum, PetSizeEnum } from "../../types/pets";

const shiftWords = () => {
  return PREFIX.indexOf(window.hph_locale || LANG.EN_US) > -1;
};

export const getPetGenderName = (genderId: number) => {
  return genderId === PetGenderEnum.Male
    ? t(MY_PETS.ADD_EDIT_PET.MALE)
    : genderId === PetGenderEnum.Female
    ? t(MY_PETS.ADD_EDIT_PET.FEMALE)
    : "-";
};

export const getPetSizeName = (petTypeId: number, sizeId: number) => {
  if (petTypeId === PetTypesEnum.Dog) {
    switch (sizeId) {
      case PetSizeEnum.Small:
        return t(MY_PETS.ADD_EDIT_PET.SMALL_WEIGHT_RANGE_DOG);
      case PetSizeEnum.Medium:
        return t(MY_PETS.ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_DOG);
      case PetSizeEnum.Large:
        return t(MY_PETS.ADD_EDIT_PET.LARGE_WEIGHT_RANGE_DOG);
      default:
        return "-";
    }
  } else if (petTypeId === PetTypesEnum.Cat) {
    switch (sizeId) {
      case PetSizeEnum.Small:
        return t(MY_PETS.ADD_EDIT_PET.SMALL_WEIGHT_RANGE_CAT);
      case PetSizeEnum.Medium:
        return t(MY_PETS.ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_CAT);
      case PetSizeEnum.Large:
        return t(MY_PETS.ADD_EDIT_PET.LARGE_WEIGHT_RANGE_CAT);
      default:
        return "-";
    }
  }
  return "-";
};

export { shiftWords };
