import { Auth, useAuth } from "../hooks/useAuth";
import axios from "axios";

export interface PostAnswerResponse {
  correctId: string;
}

export async function postQOTWAnswer({ optionId }: { optionId: string }) {
  const auth: Auth = useAuth();
  const userId = auth?.user?.UID;

  const response = await axios.post<PostAnswerResponse>(
    `${process.env.REACT_APP_QOTW_API_URL}/v1/answer`,
    { optionId, userId }
  );

  return response.data;
}
