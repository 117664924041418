import { Auth, useAuth } from "../hooks/useAuth";
import axios from "axios";

export interface FetchQOTWResponse {
  id: string;
  text: string;
  isAnswered: boolean;
  options: {
    id: string;
    text: string;
  }[];
}

export async function fetchQOTW() {
  const auth: Auth = useAuth();
  const userId = auth?.user?.UID;

  const response = await axios.get<FetchQOTWResponse>(
    `${process.env.REACT_APP_QOTW_API_URL}/v1/question-of-the-week`, {
      params: { userId }
    }
  );

  return response.data;
}
